import React, { useState } from "react";
import "./Navbar.css";
import logo from "../../assests/logo.png";

import { Link, useLocation  } from "react-router-dom";

export default function Navbar() {

  const location = useLocation();
  
  const [isOpen, setIsOpen] = useState(false);

  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  const closeNav = () => {
    setIsOpen(false);
  };

  return (
    <div className="navbar pb-[1vh]">
        <div className="flex flex-col md:flex-row justify-between">
          <div className="w-[80%] mx-auto pt-[1vh]  flex flex-row justify-between items-start md:items-center">
            <Link to="/">
              <img 
              src={logo} className="logo h-[7vh] md:h-[10vh] lg:h-[12vh]" alt="pelikaslogo" />
            </Link>

            <div 
              className="mt-[1.5vh]">
              <ul
                className={`nav-links flex flex-col gap-y-[2vh] md:w-[50vw] items-center md:flex-row  justify-between ${isOpen ? "visible" : "hidden"}`}
              >
                <div className="flex flex-col md:flex-row justify-between items-center md:w-[70%] lg:w-[40%]">
                  <li
                    className={` nav-tab text-[3vh] mt-[1vh] md:mt-[0vh] md:text-[2.5vh] lg:text-[3vh] text-gray-500 font-semibold ${location.pathname === '/' ? 'active' : ''}`}
                  >
                    <Link to="/" data-tab="home" onClick={closeNav}>
                      Home
                    </Link>
                  </li>

                  <li
                    className={`nav-tab text-[3vh] mt-[1vh] md:mt-[0vh] md:text-[2.5vh] lg:text-[3vh] text-gray-500 font-semibold ${location.pathname === '/men' ? 'active' : ''}`}
                  >
                    <Link to="/men" onClick={closeNav}>
                      Men
                    </Link>
                  </li>

                  <li
                    className={`nav-tab text-[3vh] mt-[1vh] md:mt-[0vh] md:text-[2.5vh] lg:text-[3vh] text-gray-500 font-semibold ${location.pathname === '/women' ? 'active' : ''}`}
                  >
                    <Link to="/women" onClick={closeNav}>
                      Women
                    </Link>
                  </li>
                </div>


                <Link to="/contact" onClick={closeNav}>
                  <button className="button py-[1vh] px-[2vh] md:py-[1.5vh] font-bold text-[3vh] md:px-[4vh] bg-blue-800 text-white">
                    Contact
                  </button>
                </Link>
              </ul>


            </div>

            <div
              className={`hamburger ${isOpen ? "open" : ""}`}
              onClick={toggleNav}
            >
              <div></div>
              <div></div>
              <div></div>
            </div>

          </div>
        </div>
    </div>
  );
}
