import React from "react";
import "./Footer.css";
import logo from "../../assests/logo.png";

export default function Footer() {
//   const handleclick = () => {
//     window.open("https://www.linkedin.com/in/-reena-verma/", "tab1");
//     setTimeout(() => {
//       window.open("https://www.linkedin.com/in/gauravdev04/", "tab2");
//     }, 100);
//   };

  return (
    <div className="footer py-[2vh]">
      <div className="md:w-[90%] lg:w-[80%] mx-auto flex flex-col items-center md:items-start md:flex-row justify-between">
        <div>
          <img
            src={logo}
            className="h-[8vh] md:h-[12vh] mb-[3vh] md:mb-[0vh] "
            alt="logo"
          />
        </div>

        <div className="flex flex-col  justify-center items-center md:items-start mt-[2vh]">
          <div className="flex flex-row items-center my-[1vh]">
            <i class="text-[2.5vh] md:text-[4vh] contact fa-solid fa-phone"></i>
            <div className="md:ml-[2vh]">
              <p className="text-[2vh] md:text-[2.7vh] text-gray-200">
                9152537338
              </p>
            </div>
          </div>

          <div className="flex flex-row items-center md:mt-[2vh] mb-[5vh]">
            <i class="text-[2.5vh] md:text-[4vh] contact fa-solid fa-envelope"></i>
            <div className="md:ml-[2vh]">
              <p className="text-[2vh] md:text-[2.7vh] text-gray-200">
                thebangoutfit@gmail.com
              </p>
            </div>
          </div>
        </div>
        <div>
          <h1 className="text-[3vh] md:text-[2.5vh] lg:text-[3vh] font-bold text-white mb-[1vh]text-center md:text-start">
            Quick Links
          </h1>
          <ul className="text-slate-200 mb-[3vh] md:mb-[0vh] text-center md:text-start">
            <li className="text-[2.3vh] mt-[0.5vh] lg:text-[2.6vh] hover:underline">
              Home
            </li>
            <li className="text-[2.3vh] mt-[0.5vh] lg:text-[2.6vh] hover:underline">
              About Us
            </li>
            <li className="text-[2.3vh] mt-[0.5vh] lg:text-[2.6vh] hover:underline">
              Men
            </li>
            <li className="text-[2.3vh]  mt-[0.5vh] lg:text-[2.6vh] hover:underline">
              Women
            </li>
            <li className="text-[2.3vh] mt-[0.5vh] lg:text-[2.6vh] hover:underline">
              Contact Us
            </li>
          </ul>
        </div>
        <div className="flex flex-col items-center md:items-start">
          <h1 className="text-[3vh] md:text-[2.5vh] lg:text-[3vh] font-bold text-white mb-[1vh] md:mb-[0vh]">
            Follow us on
          </h1>
          <ul>
            <a
              href="https://instagram.com/thebang_outfit?utm_source=qr&igshid=MTFoZHA1ZHB3Z2FvYg=="
              target="_blank"
              rel="noreferrer"
            >
              <i class="fa-brands fa-square-instagram"></i>
            </a>

            <a
              href="https://wa.me/919152537338"
              target="_blank"
              rel="noreferrer"
            >
              <i class="fa-brands fa-square-whatsapp"></i>
            </a>
          </ul>
        </div>
      </div>
      <hr className="mt-[3vh] h-[2px]" />
      <div className="w-[90%]  md:w-[80%] mx-auto mt-[2vh] flex flex-col md:flex-row justify-between">
        <p className=" text-white text-[1.8vh] md:text-[2.2vh] mb-[2vh] md:mb-[0vh] text-center md:text-start">
          CopyRight© 2023 The Bang Jeans. All Rights Reserved.
        </p>
        <p className="text-[1.8vh] md:text-[2.2vh] text-white text-center md:text-start">
            Designed and Developed by
            <span className="ml-[1vh]">
                <a href="https://www.linkedin.com/in/gauravdev04/" target="_blank" rel="noreferrer">
                    <i class="creditlinks fa-solid fa-g"></i>
                </a>
            </span>
            &
            <span className="ml-[1vh]">
                <a href="https://www.linkedin.com/in/-reena-verma/" target="_blank" rel="noreferrer">
                    <i class="creditlinks fa-solid fa-r"></i>
                </a>
            </span>
        </p>
      </div>
    </div>
  );
}
