import React, { useEffect, useRef } from 'react';
import Lottie from 'lottie-web';
import './Men.css'

export default function Men() {
  const container = useRef(null);

  useEffect(() => {
    Lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('../../assests/stars.json'), // Corrected path
    });
  }, []);

  return (
    <div className='men w-full'>
      <div className='w-[90%] md:w-[80%] mx-auto h-[80vh] flex flex-col justify-center items-center'>
        <h1 className='comingsoon text-[10vh] lg:text-[15vh] font-semibold text-center'>Coming Soon</h1>
        <p className='text-[2.8vh] md:text-[3.5vh] text-gray-600 text-center'>We will be making all the products available soon.</p>
        <div className='absolute -z-20 container w-[100vh] h-[60vh]' ref={container}></div>
      </div>
    </div>
  );
}
