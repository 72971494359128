import React from "react";
import "./Home.css";

// {/* images import */}
import homeimg from "../../assests/homeimg.webp";
import aboutimg from "../../assests/about.webp";
import newarrival1 from "../../assests/newarrival1.webp";
import newarrival2 from "../../assests/newarrival2.webp";
import newarrival3 from "../../assests/newarrival3.webp";
import newarrival4 from "../../assests/newarrival4.webp";
// import slider1 from "../../assests/slider1.png";
import slider2 from "../../assests/slider2.webp";
import slider3 from "../../assests/slider3.webp";
import slider4 from "../../assests/slider4.webp";

// swiper integration
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import insta from "../../assests/instagram.webp";
import whatsapp from "../../assests/whatsapp.png";

export default function Home() {
  return (
    <div>
      <div className="">

        {/* absolute insta whatsapp */}
        <div className="medialinks fixed z-50 right-[2vh] bottom-[2vh]">
          <a href="https://instagram.com/thebang_outfit?utm_source=qr&igshid=MTFoZHA1ZHB3Z2FvYg==" target="_blank" rel='noreferrer'>
            <img className="w-[7vh] my-[1vh] rounded-2xl" src={insta} alt="instagram" />
          </a>
          <a href="https://wa.me/919152537338" target='_blank' rel='noreferrer'>
            <img className="w-[7vh] my-[1vh] rounded-2xl" src={whatsapp} alt="whatsapp"/>
          </a>
        </div>

        <div className="background">
          <div className="w-[90%] md:w-[80%] mx-auto flex flex-col-reverse md:flex-row justify-between items-center py-[4vh]">
            <div className="w-[100%] md:w-[50%]">
              <h1 className="text-[3vh] md:text-[3.6vh] font-bold text-white">THE BANG</h1>
              <h1
                className="gradient-text text-[8vh] 
            md:text-[10vh] lg:text-[12vh] font-bold"
              >
                Outfit
              </h1>
              <p className="text-[2.5vh] md:text-[3vh]  text-white">
                Discover our exceptional range of clothing that redefines style and comfort. We're dedicated to creating pieces that embody both enduring quality and versatile fashion
              </p>
              <button className="button mt-[2vh] px-[3vh] py-[1vh] text-[3vh] text-white">
                Explore Now
              </button>
            </div>

            <div>
              <img
                src={homeimg}
                alt="homeimg"
                className="h-[50vh] md:h-[80vh]"
              />
            </div>
          </div>
        </div>

        {/* About Us Section */}
        <div
          name="about"
          className="w-[90%] md:w-[80%] mx-auto flex flex-col md:flex-row gap-y-[4vh] mt-[10vh]"
        >
          <img
            src={aboutimg}
            alt="aboutimg"
            className="md:w-[35vh] lg:w-[40vh] rounded-xl"
          />
          <div className="md:ml-[6vh]">
            <h1 className="text-[5vh] md:text-[7vh] text-gray-700">About Us</h1>
            <p className="text-[2.5vh] md:text-[3vh] mt-[2vh] text-justify text-gray-700">
              At Bang Outfit, we're not just about fashion; we're about setting trends, redefining styles, and empowering individuals to embrace their uniqueness.Founded by a team of passionate fashion enthusiasts, Bang Outfit started with a vision to revolutionize the online shopping experience.By choosing Bang Outfit, you're not just embracing style; you're supporting a conscientious approach to fashion.Join us on this fashion journey. Explore our latest collections, discover your signature style, and make a statement that's uniquely yours. Whether you're looking for the perfect outfit or seeking inspiration, Bang Outfit is here to elevate your fashion game
            </p>
          </div>
        </div>

        {/* New Arrival */}
        <h1 className="text-[5vh] md:text-[7vh] text-gray-700 text-center mt-[10vh] mb-[4vh]">
          New Arrivals
        </h1>
        <div className="w-[90%] md:w-[80%] mx-auto flex flex-row justify-center gap-x-[2vh] flex-wrap gap-y-[3vh] items-center">
          <div className="mx-auto md:h-[47vh] xl:h-[55vh] overflow-hidden">
            <img
              src={newarrival1}
              alt="newarrival1"
              className="newarrival h-full hover:scale-150 duration-200"
            />
          </div>

          <div className="mx-auto md:h-[47vh] xl:h-[55vh] overflow-hidden">
            <img
              src={newarrival2}
              alt="newarrival1"
              className="newarrival h-full hover:scale-150 duration-200"
            />
          </div>

          <div className="mx-auto md:h-[47vh] xl:h-[55vh] overflow-hidden">
            <img
              src={newarrival3}
              alt="newarrival1"
              className="newarrival h-full hover:scale-150 duration-200"
            />
          </div>

          <div className="mx-auto md:h-[47vh] xl:h-[55vh] overflow-hidden">
            <img
              src={newarrival4}
              alt="newarrival1"
              className="newarrival h-full hover:scale-150 duration-200"
            />
          </div>
        </div>

        {/* Moving Carosuel */}
        <div className="w-[90%] mx-auto mt-[10vh] mb-[5vh]">
          <Swiper
            loop={true}
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper w-[90vw] md:w-[80vw] mx-auto"
          >
            <SwiperSlide>
              <img
                className="sliderimg w-[100%] h-[30vh] md:h-[55vh] object-cover"
                src={slider2}
                alt="slider2"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="sliderimg w-[100%] h-[30vh] md:h-[55vh] object-cover"
                src={slider3}
                alt="slider3"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="sliderimg w-[100%] h-[30vh] md:h-[55vh] object-cover"
                src={slider4}
                alt="slider4"
              />
            </SwiperSlide>
            
          </Swiper>
        </div>
      </div>
    </div>
  );
}
