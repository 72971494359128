import React, {useState} from "react";
import "./Contact.css";

import TextField from "@mui/material/TextField";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Lottie from "lottie-react";
import Contactjson from "../../assests/contact.json";

export default function Contact() {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [message, setMessage] = useState('');

  const handleSendMessage = () => {

    if (!name || !email || !contactNo || !message) {
      toast.error('Please enter all details.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }

    toast.success('Message sent successfully!', {
      position: toast.POSITION.BOTTOM_RIGHT,
    });

    setName('');
    setEmail('');
    setContactNo('');
    setMessage('');
  };



  return (
    <div className="w-[90%] md:h-[100vh] md:w-[80%] mx-auto mb-[7vh]">
      <h1 className="text-[4vh] text-center font-semibold my-[5vh] text-gray-700">
        Contact Us
      </h1>

      <div className="contactbox w-[100%]p-[2vh] flex flex-col gap-y-[7vh] md:flex-row justify-between items-center">
        {/* contact info */}
        <div className="flex flex-col gap-y-[3vh] md:w-[45%]">

          <Lottie animationData={Contactjson} className="w-[100%]"/>

          

        </div>

        {/* contact form */}
        <div className="w-[90%] md:w-[50%]">

          <div className="my-[3vh]">
            <TextField
              id="outlined-basic"
              label="Name"
              variant="outlined"
              className="input w-[100%]"
              value={name}
              onChange={(e) => setName(e.target.value)}
            
            />
            {/* <div className="error-text">{nameError}</div> */}
          </div>

          <div className="my-[3vh]">
            <TextField 
              id="outlined-basic" 
              label="Email" 
              variant="outlined" 
              className="input w-[100%]"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {/* <div className="error-text">{emailError}</div> */}
          </div>

          <div className="my-[3vh]">
            <TextField
              id="outlined-basic"
              label="Contact No."
              variant="outlined"
              className="input w-[100%]"
              value={contactNo}
              onChange={(e) => setContactNo(e.target.value)}
            />
            {/* <div className="error-text">{contactNoError}</div> */}
          </div>

          <div className="my-[3vh]">
            <TextField
              id="outlined-multiline-static"
              label="Message"
              multiline
              rows={4}
              className="input w-[100%]"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            {/* <div className="error-text">{messageError}</div> */}
          </div>

          <button 
            className="button text-[2.4vh] px-[1.5vh] md:px-[3vh] py-[1vh] md:text-[3vh] text-white"
            onClick={handleSendMessage}
          >
            Send Message
          </button>

          <ToastContainer toastClassName="custom-toast"/>
        </div>

      </div>
    </div>
  );
}
